import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ZoomMtg } from "@zoomus/websdk";

import "./App.css";
import { getZoomSignature } from "./endpoints";
import { getAccessToken, setAccessToken } from "./lib/accessToken";
import { decrypt, generate_key } from "./lib/crypt";
import myaxios from "./lib/myaxios";
import { Response } from "./lib/types/Response";
import "@zoomus/websdk/dist/css/bootstrap.css";
import "@zoomus/websdk/dist/css/react-select.css";

function App() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState<{
    signature: string;
    meetingNumber: string;
    meetingPassword: string;
    role: string;
    email: string;
    username: string;
    leaveUrl: string;
  }>({
    signature: "",
    meetingNumber: "0",
    meetingPassword: "",
    role: "0",
    email: "",
    username: "",
    leaveUrl: "",
  });
  const [classId, setClassId] = useState("");
  const [token, setToken] = useState("");

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered

  async function getSignature(classId: string, token: string) {
    if (ZoomMtg) {
      myaxios(getZoomSignature + "/" + classId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(({ data }: { data: Response }) => {
          if (data) {
            setData((prev) => {
              return {
                ...prev,
                signature: data.data.signature,
                meetingNumber: data.data.meetingNumber,
                meetingPassword: data.data.meetingPassword,
                role: data.data.role,
              };
            });
          } else {
            toast.error("Something went wrong", {
              duration: 3000,
            });
          }
        })
        .catch((err: AxiosError) => {
          const { message } = err?.response?.data;
          toast.error(message);
          setError(message);
        });
    } else {
      toast.error("Something went wrong");
      setError("Something went wrong");
    }
    return {
      signature: "",
      meetingNumber: "0",
      meetingPassword: "",
      role: 0,
    };
  }

  function startMeeting(
    signature: string,
    meetingNumber: string,
    password: string,
    role: string, // 0 - viewer, 1 - host
    leaveUrl: string,
    username: string,
    email: string
  ) {
    if (window && document.getElementById("zmmtg-root")) {
      const zoom_root = document.getElementById("zmmtg-root");
      if (zoom_root) {
        zoom_root.style.display = "block";
      }
    }

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success: any) => {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: username,
          // apiKey: process.env.REACT_APP_ZOOM_JWT_API_KEY || "",
          userEmail: email,
          passWord: password,
          // tk: registrantToken,
          success: (success: any) => {
            toast.loading("You device is ready.", { duration: 3000 });
          },
          error: (error: any) => {
            // http
            setError(error.errorMessage);
            toast.error("Something went wrong", { duration: 3000 });
          },
        });
      },
      error: (error: any) => {
        setError(error.errorMessage);
        toast.error("Something went wrong", { duration: 3000 });
      },
    });
  }

  // check url
  useEffect(() => {
    const loadParams = async () => {
      setLoading(true);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("token");
      const ch = urlParams.get("ch"); // fallback url :: username :: email :: exptime
      const classId = urlParams.get("classId");
      if (token && ch && classId) {
        window.history.replaceState(
          "",
          document.title,
          window.location.pathname + window.location.search.split("?")[0]
        ); // remove params from url
        const cc = decrypt(ch, generate_key(classId));
        const [_fallback, _username, _email, _exptime] = cc.split("::");

        if (new Date().getTime() < Number(_exptime)) {
          setAccessToken(token);
          setData({
            ...data,
            email: _email,
            username: _username,
            leaveUrl: _fallback,
          });
          await getSignature(classId, token);

          setClassId(classId);
          setToken(token);
        } else {
          toast.error("Link expired");
          setError("Your session has expired");
        }
      } else {
        window.location.replace("https://clamphook.com");
      }
      setLoading(false);
    };
    loadParams();
  }, []);

  // start zoom sdk
  useEffect(() => {
    const loadZoom = async () => {
      if (getAccessToken() && token && classId && data.signature) {
        ZoomMtg.setZoomJSLib("https://source.zoom.us/2.14.0/lib", "/av");
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
        ZoomMtg.i18n.load("en-US");
        ZoomMtg.i18n.reload("en-US");
        ZoomMtg.generateSDKSignature({
          meetingNumber: data.meetingNumber,
          role: data.role,
          sdkKey: process.env.ZOOM_SDK_KEY || "",
          sdkSecret: process.env.ZOOM_SDK_SECRET || "",
          success: function (res: any) {
            startMeeting(
              data.signature,
              data.meetingNumber,
              data.meetingPassword,
              data.role,
              data.leaveUrl,
              data.username,
              data.email
            );
          },
          error: function (err: any) {
            setError(err.errorMessage);
          },
        });
      }
    };
    loadZoom();
  }, [data, classId, token]);

  // https://us02web.zoom.us/j/85092934301?pwd=dnlEbVFiTzBGYUoyRVRnd0kvZVV0UT09
  return (
    <div
      className="App"
      style={{
        backgroundColor: "#396AFC",
        height: "100vh",
        color: "white",
      }}
    >
      {loading ? <p>loading...</p> : null}
      {error ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <p>{error}</p>
          <a
            href="https://clamphook.com"
            style={{
              color: "white",
              textDecoration: "none",
              fontWeight: 600,
            }}
          >
            Go Back
          </a>
        </div>
      ) : null}
      <div className="ReactModal__Body--open">
        {/* <!-- added on import --> */}
        <div id="zmmtg-root"></div>
        <div id="aria-notify-area"></div>

        {/* <!-- added on meeting init --> */}
        <div className="ReactModalPortal"></div>
        <div className="ReactModalPortal"></div>
        <div className="ReactModalPortal"></div>
        <div className="ReactModalPortal"></div>
        <div className="global-pop-up-box"></div>
        <div className="sharer-controlbar-container sharer-controlbar-container--hidden"></div>
      </div>
    </div>
  );
}

export default App;
